import * as React from "react"
import Helmet from '../images/icons/helmet.svg'
import Angle from '../images/icons/angle.svg'
import Key from '../images/icons/key.svg'

const PrefabFeaturesEt = () => {
  return (
    <div className="section-1-2-center">
      <h2 className="title wel-mb-l">Miks tehasemaja?</h2>
      <div className="grid md:grid-cols-3 gap-12 md:gap-8 w-full sm:w-2/3 md:w-full mx-auto">
        <div className="text-center">
          <img src={Angle} alt="Kvaliteet" className="h-24 mx-auto wel-mb-s" />
          <h3 className="title-xs">Kvaliteet</h3>
          <p>Kaasaegsetele nõuetele vastav maja hõlmab palju keerukaid detaile (akende teipimine, aurutõkke membraanid, külmasillad, õhutihedus jms). Tehase tingimustes on märksa lihtsam kõiki neid kontrollida.</p>
        </div>

        <div className="text-center">
          <img src={Key} alt="Tarnekindlus" className="h-24 mx-auto wel-mb-s" />
          <h3 className="title-xs">Tarnekindlus</h3>
          <p>Tehases ehitamine tähendab seda, et materjalide logistika ja tööaja kasutus on läbi tootmistarkvara pideva kontrolli all. Kõik valmib täpselt siis kui peab.</p>
        </div>

        <div className="text-center">
          <img src={Helmet} alt="Tööturvalisus" className="h-24 mx-auto wel-mb-s" />
          <h3 className="title-xs">Tööturvalisus</h3>
          <p>Ehitusplatsid on reeglida väga ohtlikud — suured raskused, võimsad tööriistad, kõrgustes töötamine. Kõiki neid ohte on tehase tingimustes lihtsam vältida.</p>
        </div>
      </div>
    </div>
  )
}

export default PrefabFeaturesEt;
